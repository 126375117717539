import { Toaster } from "react-hot-toast";
import { Header } from "../../sections/homepage/header";
import * as React from "react";
import { graphql, Link, PageProps } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import BoardDisplay from "./BoardDisplay";
import { IconHome, IconHome2, IconMail, IconPhone, IconWorld } from "@tabler/icons-react";
import parse from 'html-react-parser'

const DesignDetailPage: React.FC<PageProps> = ({ data, params }) => {

  // console.log("----------------------------------")
  // console.log("START Design detail data:")
  // console.log("----------------------------------")
  // console.log(data)
  // console.log("----------------------------------")
  // console.log("END Design detail data:")
  // console.log("----------------------------------")
  const jpgNodeId = data.airtable.data.board.localFiles[0].fields.jpgNodeId
  // console.log(data.airtable.data.description)
  const websites = data.airtable.data.Website?.split(",") || []
  const emails = data.airtable.data.emailvis ? data.airtable.data.Email.split(",") : []
  const phone = data.airtable.data.phonevis ? data.airtable.data.Phone_number : null
  let archName = data.airtable.data.Your_name
  // console.log(data.airtable.data.description.childMarkdownRemark.html)
  // special case: Richard Terrones is the designer but Ted Catlin is the contact person. if this happened a lot we
  // could include a separate "contact name" in the DB, but it's just one exception so we just hardwire it!
  if (archName === "Richard Terrones") {
    archName = "Ted Catlin"
  }
  return (
    <main className="pb-16">
      <Toaster></Toaster>
      <Header></Header>
      <div className="container">
        <div className=" breadcrumbs">
          <ul className="!text-lg mb-0">
            <li><Link className="no-underline text-pink_accent" to="/"><IconHome /></Link></li>
            <li><Link className="no-underline text-pink_accent" to="/designs">Designs</Link></li>
            <li>{data.airtable.data.Title_your_entry}</li>
          </ul>
        </div>
      </div>
      <div className="container mt-8">
        <h2 className='mb-4'>{data.airtable.data.Title_your_entry}</h2>
        <p className='mb-0'>by {data.airtable.data.Your_name}</p>
        <p className='mb-0'>{data.airtable.data.Company_name}</p>
        <p className="py-4"></p>
        <BoardDisplay jpgNodeId={jpgNodeId}/>

        {/* Show additional renderings */}
        <div className="my-8 border-t-2 w-full h-1"></div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-8">
          <div className="flex flex-col gap-y-8 pb-8">
            {data.airtable.data.renderings?.localFiles?.map((file: any, idx: number) => {
              const img = getImage(file)
              if (!img) return <div>NO IMAGE</div>
              return <GatsbyImage key={idx} className="border-2 w-full" image={img}
                                  alt={data.airtable.data.Title_your_entry}/>
            })}
          </div>
          <div>
            <h3 className="mb-4">Description</h3>
            <p className='py-2 my-2'>
              {parse(data.airtable.data.description.childMarkdownRemark.html)}</p>

            <h3 className="mt-6 mb-4">Contact the architect</h3>
            <p className='mb-0 font-bold'>{archName}</p>
            <p className='mb-0'>{data.airtable.data.Company_name}</p>
            {websites.map((website: string, idx: number) => (
              <p className="mb-0 whitespace-nowrap text-ellipsis overflow-hidden ">

                <a href={"https://" + website}
                        target="_blank"
                        className="text-neutralgray-800 font-normal no-underline hover:underline"
                        rel="noreferrer">
                  <IconWorld key={idx} className="inline text-pink_accent"/> {website}
                </a>
              </p>
            ))
            }
            {emails.map((email: string, idx: number) => (
              <p className="mb-0 whitespace-nowrap text-ellipsis overflow-hidden">

                <a href={`mailto:${email}`}
                        target="_blank"
                        rel="noreferrer"
                        className="font-normal text-neutralgray-800 no-underline hover:underline">
                    <IconMail key={idx} className="inline text-pink_accent"/> {email}
                </a>
              </p>
            ))
            }
            {phone && <p><IconPhone className="inline text-pink_accent"/> {phone}</p>}
          </div>

        </div>
      </div>

    </main>
  )
}

export default DesignDetailPage

// This is the page query that connects the data to the actual component. Here you can query for any and all fields
// you need access to within your code. Gatsby always queries for `id` in the collection, so you can use that
// to connect to this GraphQL query. See https://www.gatsbyjs.com/docs/reference/routing/file-system-route-api
export const query = graphql`
  query ($id: String) {
    airtable(id: { eq: $id }) {
      data {
        Title_your_entry
        Company_name
        Your_name
        Website
        Email
        Phone_number
        emailvis
        phonevis
        board {
          localFiles {
            fields {
              jpgNodeId
            }
          }
        }
        renderings {
          localFiles {
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }

          }
        }

        description:Description {
          childMarkdownRemark {
            html
          }
        }

      }
    }
  }
`
