import * as React from "react";
import { graphql, PageProps, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const BoardDisplay: React.FC<PageProps> = ({ jpgNodeId }) => {


  const boardjpg = useStaticQuery(graphql`
      query query{
          allFile(filter: {name: {glob: "jpg-*"}}) {
              nodes {
                  id
                  childImageSharp {
                      gatsbyImageData(
                           width: 1600
                          placeholder: BLURRED
                          formats: [AUTO, WEBP, AVIF]
                      )
                  }
              }
          }
      }
  `)
  // image node is in allFile... we have to query *all* the images with matching name and filter the one we want.
  // very lame but it works.
  const imgNode = boardjpg.allFile.nodes.filter((node: any) => { return node.id === jpgNodeId})[0]
  const img = getImage (imgNode)
  if (!img) return <div>NO IMAGE</div>

  return <GatsbyImage className="border-2" image={img} alt={jpgNodeId} />
}
export default BoardDisplay
